<template>
  <div class="article-page">
    <preloader v-if="isFetch || preload"></preloader>

    <template>
      <headed-block></headed-block>

      <content-page-parallax></content-page-parallax>

      <div class="content">
        <div class="toolbar flex align-center px-5">
          <router-link
              class="btn-back flex align-center py-5"
              :to="{
              name: 'list-old',
              params: { specSlug, category: 'all', ...$route.params },
            }"
          >
            <span class="icon arrow-left"></span>
            <span class="ml-4">Всі матеріали</span>
          </router-link>

          <template v-if="tdev">
            <a
                class="btn flex align-center ml-auto"
                :href="`${$api.host}/teva_admin/pages/${data.id}/edit_components`"
                v-text="'Редагувати'"
            ></a>

            <a
                class="btn flex align-center ml-2"
                :href="`${$api.host}/teva_admin/drugs/${drugData.id}/translations`"
                v-text="'Редагувати 2'"
            ></a>
          </template>
        </div>

        <div class="wrap">
          <div class="article-wrapper">
            <h3
                class="h1-title"
                v-html="data.description"
                v-if="
                data.description !== '' &&
                data.slug !== 'p-osvitnii-modul-1-p' &&
                data.slug !== 'p-osvitnii-modul-1-tema-2-p' &&
                data.slug !== 'p-osvitnii-modul-1-tema-3-p-1' &&
                data.slug !== 'p-osvitnii-modul-2-p' &&
                data.slug !== 'p-osvitnii-modul-2-tema-1-p' &&
                data.slug !== 'p-osvitnii-modul-2-tema-2-p' &&
                data.slug !== 'p-osvitnii-modul-2-tema-3-p' &&
                data.slug !== 'p-osvitnii-modul-3-p' &&
                data.slug !== 'p-osvitnii-modul-3-tema-1-p' &&
                data.slug !== 'p-osvitnii-modul-3-tema-2-p' &&
                data.slug !== 'p-osvitnii-modul-3-tema-3-p' &&
                data.slug !== 'p-osvitnii-modul-4-p' &&
                data.slug !== 'p-osvitnii-modul-5-p' &&
                data.slug !== 'p-osvitnii-modul-5-tema-1-p' &&
                data.slug !== 'p-osvitnii-modul-5-tema-2-p' &&
                data.slug !== 'p-osvitnii-modul-5-p-1'&&
                data.slug !== 'p-osvitnii-modul-6-p'
              "
            ></h3>

            <div class="article-content">
              <template v-for="({ component, content }, key) in contentBlocks">
                <component
                    :key="key"
                    :is="component"
                    :content="content"
                ></component>

                <!-- {{ component }} -->
              </template>
            </div>

            <div
                v-if="data.promoNumber !== null"
                class="promo-main"
                v-html="data.promoNumber"
            ></div>

            <subscribe-block-second></subscribe-block-second>

            <section-links :content="drugData"></section-links>
          </div>

          <div class="more-article-wrapper">
            <div class="more-article" v-if="relatedPage !== null">
              <h2 class="h2-title-more">Вас може зацікавити:</h2>

              <div class="articles-wrapper">
                <router-link
                    v-for="(key, i) in relatedPage"
                    :key="i"
                    :to="goToArticle(key.slug)"
                    class="article-item one-section"
                    v-bind:class="[getRandomColor()]"
                >
                  <div :class="`label color${key.label.id}`">
                    <div class="icon"></div>
                  </div>

                  <img
                      class="article-img mob"
                      :src="`${$root.globalUrl}/uploads/title/${key.titlePictureMobile}`"
                      alt
                  />

                  <img
                      class="article-img desk"
                      :src="`${$root.globalUrl}/uploads/title/${key.titlePictureMobile}`"
                      alt
                  />

                  <div class="description">
                    <div class="text-wrapper">
                      <p class="h2-title" v-html="key.shortTitle"></p>
                      <p class="h3-title" v-html="key.shortDescription"></p>
                    </div>

                    <div class="more-btn">
                      <p>Детальніше</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer-block></footer-block>
    </template>
  </div>
</template>

<script>
import {get} from "lodash";
import {mapState, mapGetters, mapMutations} from "vuex";

export default {
  name: "article-page",

  data() {
    return {
      color: ["color1", "color2", "color3"],
      preload: false,
      data: {},
    };
  },

  computed: {
    ...mapGetters("Spec", ["currentSpec"]),
    ...mapState("Spec", ['isFetch']),

    specId() {
      return this.currentSpec.id || 0;
    },
    specSlug() {
      return this.currentSpec.slug || 0;
    },

    tdev() {
      return window.tdev;
    },

    relatedPage() {
      return get(this.data, "relatedPages", null);
    },

    drugData() {
      return get(this.data, "drug", {});
    },

    content() {
      return JSON.parse(get(this.data, "content", "[]"));
    },

    contentBlocks() {
      return this.content.reduce((r, el) => {
        const component = this.getComponent(el);

        if (component) {
          r.push({component, content: el});
        }

        return r;
      }, []);
    },
  },

  methods: {
    ...mapMutations(["setScrollBehavior", "setScrollValue", "setPagePopup"]),

    getComponent(item) {
      console.log(item.type)
      switch (item.type) {
        case "sumamed_form_drug":
          return "Sumamed";

        case "training_module_1":
          return "TrainingModule1Theme1";

        case "training_module_1_part_2":
          return "TrainingModule1Theme2";

        case "training_module_1_part_3":
          return "TrainingModule1Theme3";

        case "training_module_2_part_1":
          return "TrainingModule2";

        case "training_module_2_part_2":
          return "TrainingModule2Theme1";

        case "training_module_2_part_3":
          return "TrainingModule2Theme2";

        case "training_module_agrippa":
          return "TrainingModule6";

        case "training_module_2_part_4":
          return "TrainingModule2Theme3";

        case "olfen75_theme_1":
          return "TrainingModule3";

        case "olfen75_theme_2":

          return "TrainingModule3Theme1";
        case "olfen75_theme_3":
          return "TrainingModule3Theme2";

        case "olfen75_theme_4":
          return "TrainingModule3Theme3";

        case "training_module_nuvidzhil":
          return "TrainingModule4";

        case "training_module_5":
          return "TrainingModule5";

        case "training_module_5_theme_1":
          return "TrainingModule5Theme1";

        case "training_module_5_theme_2":
          return "TrainingModule5Theme2";

        case "text_image_description":
          return "section-full-chart";

        case "video_360":
          return "section-video-360";

        case "text_full_width":
          return "section-reg-text";

        case "video_instruction":
          return "section-video-instruction";

        case "reg_list":
          return "section-ref";

        case "big_list":
          return "section-col-list-text";

        case "text_full_width_with_background":
          return "section-background-text-img";

        case "text_with_title_horizontal":
          return "section-double-row-text";

        case "text_with_title_vertical":
          return "section-double-col-text";

        case "image_full_width":
          return "section-main-img";

        case "diagram_type_1":
          return "section-diagram-type1";

        case "download_example":
          return "section-download";

        case "image_left_text_with_background_right":
          return "section-image_left_text_with_background_right";

        case "image_right_text_with_background_left":
          return "section-image_right_text_with_background_left";

        case "list_collapse":
          return "accordion";

        case "slider_content":
          return "tab";

        case "two_icons_with_background_text_right":
          return "section-recommend";

        case "two_icons_with_background_text":
          return "section-row-double-icon-background-text";

        case "four_icons_with_background_text":
          return "section-row-icon-background-text";

        case "download_app":
          return "download-app";

        case "image_left_paragraph_right":
          return "section-row-drug-use";

        case "image_right_paragraph_left":
          return "section-study-design";

        case "photo_picker":
          return "section-picker";

        case "single_video":
          return "section-single-video";

        case "slider_with_video":
          return "section-video";
        case "slider_with_youtube_video":
          return "section-video";

        case "image_full_width_slider":
          return "section-slider-wrapper";

        case "item_preview_title":
          return "section-drug-description";
        case "item_preview_content":
          return "section-drug-description";
        case "item_preview_dosing":
          return "section-drug-description";
        case "item_preview_instruction":
          return "section-drug-description";
        case "button_concent":
          return "section-drug-description";

        case "table_with_percents":
          return "section-table";

        case "diagram_type_2":
          return "section-diagram-type2";

        case "title_description_image_vertical":
          return "section-row-img-text";

        case "slider_with_image_right":
          return "section-text-carousel";

        case "big_list_right":
          return "section-text-big-list";

        case "title_description_image_horizontal":
          return "section-col-img-text";

        case "title_description_image_right":
          return "section-row-text-img";

        case "list_with_points":
          return "section-blockquote-list";
        case "quote_full_width":
          return "section-blockquote-list";
        case "list_with_points_two":
          return "section-blockquote-list";

        case "list_with_underline":
          return "section-list";

        case "list_with_circle_number":
          return "section-list-text";
        case "list_with_circle_number_two":
          return "section-list-text";

        case "phone_slider":
          return "section-app-carousel";

        case "text_passage_black":
          return "section-row-text-padding";

        case "big_list_2_columns":
          return "section-row-list-text";

        case "text_passage":
          return "section-row-col-text";

        case "quote_text":
          return "section-row-blockquote";

        case "text_passage_underline":
          return "section-double-list";

        case "icons_with_text_horizontal":
          return "section-double-row-text-icon";

        case "icons_with_text_vertical":
          return "section-double-col-text-icon";

        case "icons_without_background":
          return "section-row-icon-text";

        case "poll":
          return "section-question";

        case "first_template":
          return "section-temp-first";

        case "second_template":
          return "section-temp-second";

        case "floating_action_button":
          return "section-sticky-btn";

        case "webinar_room":
          return "webinar_room";

        default:
          return "";
      }
    },

    getRandomColor() {
      return this.color[Math.floor(Math.random() * this.color.length)];
    },

    goToArticle(id) {
      return {
        name: "article-old",
        params: {
          specSlug: this.currentSpec.slug,
          category: "all",
          ...this.$route.params,
          id,
        },
      };
    },

    async getArticle() {
      this.preload = true;

      try {
        const {id, spec = this.specId} = this.$route.params;
        this.data = await this.$api.fetchArticle(id, {spec});
        this.setPopup();
        this.setTitle();

        this.preload = false;
      } catch (e) {
      }
    },

    setPopup() {
      if (this.data.popup !== null && !this.data.popup.isActive) {
        this.$store.dispatch("setScrollBehavior", this.data.popupScroll);
        this.$store.dispatch("setScrollValue", this.data.popupValue);
        this.$store.dispatch("setPagePopup", this.data.popup);
      }
    },

    setTitle() {
      document.title = this.data.description.replace(/<\/?[^>]+>/g, "");
      document.querySelector('meta[property="og:image"]').content =
          this.$root.globalUrl + "/uploads/title/" + this.data.titlePicture;
    },
  },

  watch: {
    isFetch(val) {
      if (!val) this.getArticle();
    },

    $route(to, from) {
      this.getArticle();
    },
  },

  mounted() {
    if (!this.isFetch) this.getArticle();
  },

  beforeRouteLeave(to, from, next) {
    const category1 = from.params.category;
    const category2 = to.params.category;
    const isClear = category1 !== category2;

    if (isClear) this.$store.commit("Article/setFilter", {});

    next();
  },
};
</script>

<style lang="scss">
@import "../css/variables";

$maxW: 1100px;
$contentMaxWidthLg: 825px;
$sideMaxWidthLg: 265px;
$marginSizeLg: 20px;

$wrapMaxWidthLg: calc($contentMaxWidthLg + $sideMaxWidthLg + $marginSizeLg);

.icon {
  display: inline-block;
  width: 15px;
  height: 15px;

  background-position: center;
  background-repeat: no-repeat;

  &.arrow-left {
    transform: rotate(180deg);
    background-image: url("../assets/icon/arrow-right.svg");
  }
}

.toolbar {
  position: relative;
  display: flex;

  margin: 0 auto;
  width: 100%;
  max-width: $maxW;

  .btn-back {
    text-decoration: none;

    font-family: $fontSecondary;
    font-size: 12px;
    font-weight: 400;
    color: #317575;

    @media (min-width: $md) {
      font-size: 14px;
    }
  }
}

.article-page {
  width: 100%;

  .promo-main {
    color: #545454;
    // font-family: $fontPrimary;
    font-weight: 400;
    font-size: 12px;
  }

  .content {
    position: relative;
    z-index: 2;

    margin: 60px auto 40px auto;

    @media (min-width: $md) {
      margin-top: 80px;
    }
  }

  .wrap {
    margin: 0 auto;

    width: 100%;
    max-width: $wrapMaxWidthLg;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1090px) {
      max-width: 100%;
    }
  }

  .h1-title,
  .h2-title,
  .h3-title {
    * {
      font-weight: inherit;
    }
  }

  .h1-title {
    margin: 10px 0;

    font-weight: 600;
    font-size: 40px;
    color: #00a03b;

    @media (max-width: 670px) {
      margin: 5px 0;
      font-size: 18px;
    }
  }

  .h2-title {
    margin: 10px 0;

    font-weight: 600;
    font-size: 32px;
    color: #545454;

    @media (max-width: 670px) {
      margin: 10px 0;
      font-size: 18px;
    }

    span {
      text-decoration: underline;
      color: #00a03b;
    }
  }

  .h3-title {
    margin: 5px 0;

    font-weight: 600;
    font-size: 30px;
    color: #00a03b;

    @media (max-width: 670px) {
      margin: 10px 0;
      font-size: 18px;
    }
  }
}

.article-wrapper {
  display: flex;
  flex-direction: column;

  padding-left: 10px;

  width: 100%;
  max-width: $contentMaxWidthLg;

  * {
    font-family: inherit;
  }

  @media (max-width: 1090px) {
    padding: 0 10px;
    max-width: 100%;
  }
}

.more-article-wrapper {
  width: 100%;
  max-width: 265px;

  @media (max-width: 1090px) {
    padding: 0 10px;
    max-width: 100%;
  }

  @media (max-width: $sm) {
  }

  .back-btn {
    cursor: pointer;
    background: #aa198b;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px 0 35px;
    height: 63px;
    border-radius: 50px;
    @media (max-width: 1090px) {
      display: none;
    }

    &:hover {
      span {
        transform: translateX(-20px);
      }
    }

    p {
      color: white;
      font-size: 22px;
      // font-family: $fontPrimary;
      font-weight: 400;
    }

    span {
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
    }
  }

  .h2-title-more {
    margin: 20px 0 30px 0;
    color: #00a03b;
    // font-family: $fontPrimary;
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 1090px) {
      margin: 0 0 15px 0;
    }
  }

  .articles-wrapper {
    display: flex;
    flex-flow: column;

    @media (max-width: 1090px) {
      flex-flow: row wrap;
      justify-content: center;
    }

    .article-item {
      position: relative;

      display: flex;
      flex-direction: column;

      margin-bottom: 30px;
      max-height: 302px;

      text-decoration: none;
      color: inherit;

      @media (max-width: 1090px) {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 16px;
      }

      @media (max-width: $sm) {
        margin: 0;
        margin-bottom: 16px;

        width: 100%;
        height: auto;

        max-width: 425px;
        max-height: 1000px;
      }

      &:hover {
        .more-btn {
          background: #00a03b !important;

          p {
            color: white !important;
          }
        }
      }

      &.last-block {
        min-height: 282px;
        background: #f1f1f1;
        max-width: 262px;
        width: 100%;
        position: relative;
        padding-bottom: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $md) {
          display: none;
        }

        .next-page {
          cursor: pointer;

          * {
            transition: all 0.2s ease;
          }

          &:hover {
            circle {
              fill: white;
            }

            path {
              stroke: #7fc25d;
            }
          }
        }

        .icon {
          margin-bottom: 10px;
        }

        .next-text {
          color: #545454;
          // font-family: $fontPrimary;
          font-weight: 400;
          font-size: 14px;
        }
      }

      &.one-section {
        max-width: 262px;
        width: 100%;
        position: relative;
        padding-bottom: 20px;

        @media (max-width: $sm) {
          max-width: $sm;
        }

        &.color1 {
          background: #00a03b;
        }

        &.color2 {
          background: #379c78;
        }

        &.color3 {
          background: #005a85;
        }

        .more-btn {
          cursor: pointer;
          // user-select: none;
          max-width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto 0 0 auto;
          background: #f5f5f5;
          border-radius: 50px 0 0 50px;
          transition: all 0.2s ease;

          &:hover {
            background: #00a03b;

            p {
              color: white;
            }
          }

          * {
            transition: all 0.2s ease;
          }

          p {
            padding: 6px 10px;
            // font-family: $fontPrimary;
            font-weight: 400;
            font-size: 9px;
            color: #00a03b;
          }
        }

        .description {
          width: 100%;
          @media (max-width: $sm) {
            max-height: 1000px;
            height: auto;
          }

          .text-wrapper {
            max-height: 87px;
            overflow: hidden;
            padding: 18px 22px 10px 22px;
            margin-bottom: 5px;
            @media (max-width: $sm) {
              max-height: 1000px;
              height: auto;
            }
          }

          .h2-title {
            color: white;
            font-size: 14px;
            // font-family: $fontPrimary;
            font-weight: 400;
            margin: 0 !important;
          }

          .h3-title {
            margin-top: 5px;
            // font-family: $fontPrimary;
            font-weight: 400;
            font-size: 11px;
            color: white;
          }
        }

        .article-img {
          width: 100%;
          height: auto;
          display: block;

          &.desk {
            @media (max-width: $sm) {
              display: none;
            }
          }

          &.mob {
            display: none;
            @media (max-width: $sm) {
              display: block;
            }
          }
        }
      }

      .label {
        width: 26px;
        height: 36px;
        position: absolute;
        right: 15px;
        top: 0;
        border-radius: 0 0 50px 50px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 7px;

        .icon {
          width: 12px;
          height: 12px;
        }

        &.color1 {
          background: #aa198b;

          .icon {
            background: url("../assets/img/page/label-instrument.png") no-repeat center;
            background-size: contain;
          }
        }

        &.color2 {
          background: #b3d06b;

          .icon {
            background: url("../assets/img/page/label-useful.png") no-repeat center;
            background-size: contain;
          }
        }

        &.color3 {
          background: #fb3449;

          .icon {
            background: url("../assets/img/page/label-interesting.png") no-repeat center;
            background-size: contain;
          }
        }

        &.color4 {
          background: #005a85;

          .icon {
            background: url("../assets/img/page/label-event.png") no-repeat center;
            background-size: contain;
          }
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
}

@media (max-width: $md) {
  #p-osvitnii-modul-1-p,
  #p-osvitnii-modul-2-p,
  #p-osvitnii-modul-2-tema-1-p,
  #p-osvitnii-modul-2-tema-2-p,
  #p-osvitnii-modul-2-tema-3-p,
  #p-osvitnii-modul-3-p,
  #p-osvitnii-modul-3-tema-1-p,
  #p-osvitnii-modul-3-tema-2-p,
  #p-osvitnii-modul-3-tema-3-p,
  #p-osvitnii-modul-4-p {
    .article-wrapper {
      > .h2-title {
        display: none;
      }
    }

    &.article-page {
      > .content {
        margin: 65px auto 40px auto;
      }
    }
  }
}

@media (max-width: $sm) {
  #p-osvitnii-modul-1-p,
  #p-osvitnii-modul-2-p,
  #p-osvitnii-modul-2-tema-1-p,
  #p-osvitnii-modul-2-tema-2-p,
  #p-osvitnii-modul-2-tema-3-p,
  #p-osvitnii-modul-3-p,
  #p-osvitnii-modul-3-tema-1-p,
  #p-osvitnii-modul-3-tema-2-p,
  #p-osvitnii-modul-3-tema-3-p,
  #p-osvitnii-modul-4-p {
    &.article-page {
      > .content {
        margin: 50px auto 40px auto;
      }
    }
  }
}
</style>
