var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article-page"},[(_vm.isFetch || _vm.preload)?_c('preloader'):_vm._e(),[_c('headed-block'),_c('content-page-parallax'),_c('div',{staticClass:"content"},[_c('div',{staticClass:"toolbar flex align-center px-5"},[_c('router-link',{staticClass:"btn-back flex align-center py-5",attrs:{"to":{
            name: 'list-old',
            params: Object.assign({}, {specSlug: _vm.specSlug, category: 'all'}, _vm.$route.params),
          }}},[_c('span',{staticClass:"icon arrow-left"}),_c('span',{staticClass:"ml-4"},[_vm._v("Всі матеріали")])]),(_vm.tdev)?[_c('a',{staticClass:"btn flex align-center ml-auto",attrs:{"href":((_vm.$api.host) + "/teva_admin/pages/" + (_vm.data.id) + "/edit_components")},domProps:{"textContent":_vm._s('Редагувати')}}),_c('a',{staticClass:"btn flex align-center ml-2",attrs:{"href":((_vm.$api.host) + "/teva_admin/drugs/" + (_vm.drugData.id) + "/translations")},domProps:{"textContent":_vm._s('Редагувати 2')}})]:_vm._e()],2),_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"article-wrapper"},[(
              _vm.data.description !== '' &&
              _vm.data.slug !== 'p-osvitnii-modul-1-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-1-tema-2-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-1-tema-3-p-1' &&
              _vm.data.slug !== 'p-osvitnii-modul-2-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-2-tema-1-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-2-tema-2-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-2-tema-3-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-3-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-3-tema-1-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-3-tema-2-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-3-tema-3-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-4-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-5-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-5-tema-1-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-5-tema-2-p' &&
              _vm.data.slug !== 'p-osvitnii-modul-5-p-1'&&
              _vm.data.slug !== 'p-osvitnii-modul-6-p'
            )?_c('h3',{staticClass:"h1-title",domProps:{"innerHTML":_vm._s(_vm.data.description)}}):_vm._e(),_c('div',{staticClass:"article-content"},[_vm._l((_vm.contentBlocks),function(ref,key){
            var component = ref.component;
            var content = ref.content;
return [_c(component,{key:key,tag:"component",attrs:{"content":content}})]})],2),(_vm.data.promoNumber !== null)?_c('div',{staticClass:"promo-main",domProps:{"innerHTML":_vm._s(_vm.data.promoNumber)}}):_vm._e(),_c('subscribe-block-second'),_c('section-links',{attrs:{"content":_vm.drugData}})],1),_c('div',{staticClass:"more-article-wrapper"},[(_vm.relatedPage !== null)?_c('div',{staticClass:"more-article"},[_c('h2',{staticClass:"h2-title-more"},[_vm._v("Вас може зацікавити:")]),_c('div',{staticClass:"articles-wrapper"},_vm._l((_vm.relatedPage),function(key,i){return _c('router-link',{key:i,staticClass:"article-item one-section",class:[_vm.getRandomColor()],attrs:{"to":_vm.goToArticle(key.slug)}},[_c('div',{class:("label color" + (key.label.id))},[_c('div',{staticClass:"icon"})]),_c('img',{staticClass:"article-img mob",attrs:{"src":((_vm.$root.globalUrl) + "/uploads/title/" + (key.titlePictureMobile)),"alt":""}}),_c('img',{staticClass:"article-img desk",attrs:{"src":((_vm.$root.globalUrl) + "/uploads/title/" + (key.titlePictureMobile)),"alt":""}}),_c('div',{staticClass:"description"},[_c('div',{staticClass:"text-wrapper"},[_c('p',{staticClass:"h2-title",domProps:{"innerHTML":_vm._s(key.shortTitle)}}),_c('p',{staticClass:"h3-title",domProps:{"innerHTML":_vm._s(key.shortDescription)}})]),_c('div',{staticClass:"more-btn"},[_c('p',[_vm._v("Детальніше")])])])])}),1)]):_vm._e()])])]),_c('footer-block')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }